import React, { FunctionComponent } from 'react';

interface Props {
  id: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  multiLine?: boolean;
  required?: boolean;
  valid?: boolean;
  type?: string;
  icon?: string;
}

const Textbox: FunctionComponent<Props> = ({
  id,
  value,
  placeholder,
  onChange,
  multiLine,
  required = false,
  valid = true,
  type = 'text',
  icon,
}) => {
  return (
    <div className={`form__row ${multiLine ? 'form__row--full' : ''} ${!valid ? 'invalid' : ''}`}>
      <div className="form__control">
        {icon && <div className="form__control--icon">%</div>}
        {multiLine && (
          <textarea
            id={id}
            placeholder={`${placeholder} ${required ? '*' : ''}`}
            value={value}
            onChange={(e) => onChange(e.currentTarget.value)}
          />
        )}
        {!multiLine && (
          <input
            type={type}
            id={id}
            placeholder={`${placeholder} ${required ? '*' : ''}`}
            value={value}
            onChange={(e) => onChange(e.currentTarget.value)}
          />
        )}
        <label htmlFor={id} className="form__label">
          {`${placeholder} ${required ? '*' : ''}`}
        </label>
      </div>
    </div>
  );
};

export default Textbox;
