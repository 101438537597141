import { PDFItemType, PDFType } from '@core/enums/project';
import { IApiError, IFetchProjectPdfResponse, IUpdateProjectRequest } from '@core/types/api';
import { FormValue, SetPDFModal } from '@core/types/models';

export const GENERATE_PDF = 'PROJECTS/GENERATE_PDF';
export const GENERATE_PDF_SUCCESS = 'PROJECTS/GENERATE_PDF_SUCCESS';

export const SEND_FOR_APPROVAL = 'PROJECTS/SEND_FOR_APPROVAL';
export const SEND_FOR_APPROVAL_SUCCESS = 'PROJECTS/SEND_FOR_APPROVAL_SUCCESS';
export const SEND_FOR_APPROVAL_ERROR = 'PROJECTS/SEND_FOR_APPROVAL_ERROR';

export const SET_PDF_MODAL = 'PROJECTS/SET_PDF_MODAL';
export const CLEAR_PDF = 'PROJECTS/CLEAR_PDF';
export const DOWNLOAD_PDF = 'PROJECTS/DOWNLOAD_PDF';

export const SET_ORDER_DETAILS = 'PROJECTS/SET_ORDER_DETAILS';
export const SET_ITEM_TYPE = 'SET_ITEM_TYPE';

export const FETCH_PROJECT_PDF = 'PROJECTS/FETCH_PROJECT_PDF';
export const FETCH_PROJECT_PDF_SUCCESS = 'FETCH_PROJECT_SUCCESS/FETCH_PROJECT_PDF_SUCCESS';
export const FETCH_PROJECT_PDF_ERROR = 'PROJECTS/FETCH_PROJECT_PDF_ERROR';

export const RESET_PDF = 'PROJECTS/RESET_PDF';
export const SET_CURRENCY = 'PROJECTS/SET_CURRENCY';

export const SET_FIELD = 'PROJECTS/SET_FIELD';
export const RESET_FORM = 'PROJECTS/RESET_FORM';

export interface IGeneratePDF {
  type: typeof GENERATE_PDF;
  payload: PDFType;
}

export interface IGeneratePDFSuccess {
  type: typeof GENERATE_PDF_SUCCESS;
  payload: {
    response: IFetchProjectPdfResponse;
    type: PDFType;
  };
}

export interface ISendForApproval {
  type: typeof SEND_FOR_APPROVAL;
}

export interface ISendForApprovalSuccess {
  type: typeof SEND_FOR_APPROVAL_SUCCESS;
  payload: boolean;
}

export interface ISendForApprovalError {
  type: typeof SEND_FOR_APPROVAL_ERROR;
  payload: IApiError[];
}

export interface ISetPDFModal {
  type: typeof SET_PDF_MODAL;
  payload: SetPDFModal;
}

export interface IClearPDF {
  type: typeof CLEAR_PDF;
  payload: PDFType;
}

export interface IDownloadPDF {
  type: typeof DOWNLOAD_PDF;
}

export interface ISetItemType {
  type: typeof SET_ITEM_TYPE;
  payload: PDFItemType | null;
}

export interface ISetOrderDetails {
  type: typeof SET_ORDER_DETAILS;
  payload: Record<string, string> | null;
}

export interface IFetchProjectPdf {
  type: typeof FETCH_PROJECT_PDF;
}

export interface IFetchProductPdfSuccess {
  type: typeof FETCH_PROJECT_PDF_SUCCESS;
  payload: IFetchProjectPdfResponse;
}

export interface IFetchProductPdfErrors {
  type: typeof FETCH_PROJECT_PDF_ERROR;
  payload: IApiError[];
}

export interface IAddProductsSuccess {
  type: typeof RESET_PDF;
  payload: IUpdateProjectRequest;
}

export interface ISetCurrency {
  type: typeof SET_CURRENCY;
  payload: string;
}

export interface ISetField {
  type: typeof SET_FIELD;
  payload: { field: string; value: FormValue };
}

export type PdfSettingsActionTypes =
  | IGeneratePDF
  | IGeneratePDFSuccess
  | ISendForApproval
  | ISendForApprovalError
  | ISendForApprovalSuccess
  | ISetPDFModal
  | IClearPDF
  | IDownloadPDF
  | ISetItemType
  | ISetOrderDetails
  | IFetchProjectPdf
  | IFetchProductPdfSuccess
  | IFetchProductPdfErrors
  | IAddProductsSuccess
  | ISetCurrency
  | ISetField;
