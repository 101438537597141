import { Models } from '@core/types';
import { ProjectEnums, CategoryEnums } from '@core/enums';
import {
  DownloadsByCulture,
  DownloadsByUser,
  FormValue,
  PrintsByCulture,
  PrintsByUser,
  Product,
  ProjectPDF,
  Template,
} from './models';
import { PDFItemType, PDFType } from '@core/enums/project';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export interface IRequest {
  method: Method;
  slug: string;
  params?: any;
  controller: string;
  cacheKey?: string;
  bearerToken?: string;
  baseUrl?: string;
  isMultipartFormData?: boolean;
  files?: File[];
}

export interface IApiError {
  fieldName: string;
  message: string;
}

export interface IBaseResponse<T> {
  data: T | null;
  errors: IApiError[];
  success: boolean;
}

export interface IBaseAuthorisedRequest {
  bearerToken: string;
}

export interface IFetchProjectsRequest extends IBaseAuthorisedRequest {}

export interface IFetchProjectsResponse {
  projects: Models.Project[];
}

export interface IFetchProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
}

export interface IFetchProjectPdfRequest extends IBaseAuthorisedRequest {
  projectPDFID: number;
}

export interface IFetchProjectPdfResponse {
  projectPDF: ProjectPDF;
}

export interface IFetchProjectResponse {
  project: Models.Project;
}

export interface ICreateProjectRequest extends IBaseAuthorisedRequest {
  projectName: string;
}

export interface ICreateProjectResponse {
  projectGUID: string;
}

export interface IDeleteProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
}

export interface IFetchProductsRequest extends IBaseAuthorisedRequest {
  cultureCode: string;
  articleNumbers?: string[];
  systems?: CategoryEnums.SystemIDs[];
  variantAgilityIDs?: number[];
  categoryAgilityIDs?: CategoryEnums.AgilityIDs[];
  searchTerm?: string;
}

export interface IFetchProductsResponse {
  categories: Models.Category[];
}

export interface IFetchNavigationResponse {
  handTools: Models.Category | null;
  storage: Models.Category | null;
  powerTools: Models.Category | null;
  m18: Models.Category | null;
  m12: Models.Category | null;
  mxFuel: Models.Category | null;
}

export interface IUpdateProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
  projectProducts: Models.ProjectProduct[];
  cultureCode: string;
  refetchProject: boolean;
}

export interface PDFProps {
  type: PDFType;
  itemType: PDFItemType;
  template: Template;
  formData: Record<string, FormValue>;
  products?: Product[];
}

export interface IUpdateFlyerDataRequest extends Omit<IBaseAuthorisedRequest, 'bearerToken'> {
  flyerGUID: string;
  projectGUID: string;
  flyerData: string;
  flyerProducts: number[];
}

export interface IGeneratePDFRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
  flyerGUID: string | null;
  cultureCode: string;
  size: string;
  headerLayout: ProjectEnums.PDFHeaderLayout;
  orderNumber?: string;
  orderCompanyName?: string;
  orderFirstName?: string;
  orderLastName?: string;
  orderEmailAddress?: string;
  orderAddressLine1?: string;
  orderAddressLine2?: string;
  orderCity?: string;
  orderPostcode?: string;
  orderCountry?: string;
  invoicedCountry?: string;
  orderNotes?: string;
  fullDownload: boolean;
}

export interface IUpdateFlyerAndGeneratePDFRequest
  extends Omit<IGeneratePDFRequest, 'flyerGUID' | 'size' | 'headerLayout' | 'fullDownload' | 'cultureCode'> {
  flyerGUID?: string;
  flyerData: PDFProps;
}

export interface IGeneratePDFResponse {
  projectPDFID: number;
}

export interface IUpdateQuantity {
  agilityID: number;
  quantity: number;
}

export interface IFetchPdfsRequest extends IBaseAuthorisedRequest {
  cultureCode: string[];
  status: ProjectEnums.PrinterApprovalStatus;
}

export interface IFetchPdfsResponse {
  projectPDFs: Models.ProjectPDF[];
}

export interface IPrinterApproveReject extends IBaseAuthorisedRequest {
  projectPDFID: number;
  acceptReject: boolean;
  cultureCode: string;
}

export interface ISendForApprovalRequest extends IBaseAuthorisedRequest {
  projectPDFID: number;
  cultureCode: string;
}

export interface IFetchTranslationsResponse {
  Localisations: any;
}

export interface IFetchUserPdfsRequest extends IBaseAuthorisedRequest {
  cultureCode?: string;
  status?: ProjectEnums.PrinterApprovalStatus;
}

export interface IFetchUserPdfsResponse {
  projectPDFs: Models.ProjectPDF[];
}

export interface IFetchReportsRequest extends IBaseAuthorisedRequest {
  dateFrom: string;
  dateTo: string;
}

export interface IFetchReportsResponse {
  printsByUser: PrintsByUser[];
  printsByCulture: PrintsByCulture[];
  downloadsByUser: DownloadsByUser[];
  downloadsByCulture: DownloadsByCulture[];
}

export enum ReportType {
  PrintsByCulture = 0,
  PrintsByUser,
  DownloadsByCulture,
  DownloadsByUser,
}
