import React, { FunctionComponent, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { Api } from '@core/types';
import classNames from 'classnames';
import { MdKeyboardArrowDown } from 'react-icons/md';

interface IProps {
  title: string;
  productAgilityId?: number;
  quantity?: number;
  isSelected: boolean;
  onChange?: (agilityID: number) => void;
  onAccordionClick?: () => void;
  isDark?: boolean;
  isGrey?: boolean;
  showAccordion?: boolean;
  accordionOpen?: boolean;
  onUpdateQuantity?: (params: Api.IUpdateQuantity) => void;
  isHalf?: boolean;
  isTitle?: boolean;
}

const ProductRow: FunctionComponent<IProps> = ({
  title,
  productAgilityId,
  isSelected,
  onChange,
  onUpdateQuantity,
  onAccordionClick,
  isDark = false,
  isGrey = false,
  showAccordion = false,
  accordionOpen = false,
  quantity,
  isHalf = false,
  isTitle = false,
}) => {
  const [_quantity, _setQuantity] = useState<number>(quantity || 0);
  const fieldID = uniqueId('product_');

  const itemClassNames = classNames({
    'product-select__product-item': true,
    'product-select__product-item--dark': isDark,
    'product-select__product-item--grey': isGrey,
    'product-select__product-item--half': isHalf,
    'accordion-open': accordionOpen,
    'title-layout': isTitle,
  });

  const labelClassNames = classNames({
    'product-select__product-item--label': true,
    'hide-checkbox': onChange === undefined,
  });

  const handleQuantity = (increase: boolean) => {
    if (!quantity || !onUpdateQuantity || !productAgilityId) {
      return;
    }

    let newQuantity = _quantity;

    if (increase) {
      newQuantity += 1;
    } else {
      newQuantity -= 1;
    }

    if (newQuantity < 0) {
      newQuantity = 0;
    }

    onUpdateQuantity({
      agilityID: productAgilityId,
      quantity: newQuantity,
    });

    // update our local UI state
    _setQuantity(newQuantity);
  };

  const handleAccordionClickInternal = (e: React.MouseEvent) => {
    if (!onAccordionClick) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();

    onAccordionClick();
  };

  return (
    <div key={title} className={itemClassNames}>
      {onChange && (
        <input
          id={fieldID}
          type="checkbox"
          checked={isSelected}
          onChange={() => onChange(productAgilityId || -1)}
          className="product-select__product-item--input"
        />
      )}
      <label htmlFor={fieldID} className={labelClassNames}>
        {title}
        {quantity && productAgilityId && (
          <div className="product-select__product-item--controls">
            <button onClick={() => handleQuantity(false)}>-</button>
            {_quantity}
            <button onClick={() => handleQuantity(true)}>+</button>
          </div>
        )}
        {showAccordion && (
          <MdKeyboardArrowDown className="product-select__product-item--arrow" onClick={handleAccordionClickInternal} />
        )}
      </label>
    </div>
  );
};

export default ProductRow;
