import { PDFFlyerStep, PDFFlyerStepOption, PDFHeaderLayout, PDFSize } from '@core/enums/project';
import { Field, ProductField, Template } from '@core/types/models';

export const currencies = [
  { key: 'kr', value: '[[x]] kr' },
  { key: '€', value: '€[[x]]' },
];

export const percentage = [{ key: '%', value: '[[x]]%' }];

export const PDFFlyerStepOptions: PDFFlyerStepOption[] = [
  {
    id: PDFFlyerStep.FORMAT,
    title: 'Choose Format',
    longTitle: 'Choose your format',
    description: `Select which format you'd like your fact tags to be generated in.`,
  },
  {
    id: PDFFlyerStep.CONTENT,
    title: 'Content',
    description: `Input further information to customize the flyer.`,
  },
  {
    id: PDFFlyerStep.ORDER,
    title: 'Order Products',
  },
  {
    id: PDFFlyerStep.PRODUCTS,
    title: 'Products',
    longTitle: 'Product Details',
  },
  {
    id: PDFFlyerStep.CREATE,
    title: 'Create',
    longTitle: 'Disclaimer',
    description: 'ftg.generatepdf.disclaimer',
  },
];

const fields: Field[] = [
  {
    label: 'Select a Language',
    step: PDFFlyerStep.FORMAT,
    name: 'cultureCode',
    type: 'select',
    options: 'cultureCode',
    required: true,
  },
  {
    label: 'Select a Template',
    step: PDFFlyerStep.FORMAT,
    name: 'template',
    type: 'select',
    options: 'template',
    required: true,
  },
  {
    label: 'Export with Milwaukee® Logo',
    step: PDFFlyerStep.FORMAT,
    name: 'headerLayout',
    type: 'boolean',
    value: PDFHeaderLayout.Normal,
  },
  {
    label: 'Title',
    step: PDFFlyerStep.CONTENT,
    name: 'title',
    type: 'string',
    required: true,
  },
  {
    label: 'Subtitle',
    step: PDFFlyerStep.CONTENT,
    name: 'subtitle',
    type: 'string',
  },
  {
    label: 'Title Image',
    step: PDFFlyerStep.CONTENT,
    name: 'titleImage',
    type: 'file',
    required: true,
  },
  {
    label: 'Dealer Logo',
    step: PDFFlyerStep.CONTENT,
    name: 'dealerLogo',
    type: 'file',
    required: true,
  },
  {
    label: 'Dealer Address',
    step: PDFFlyerStep.CONTENT,
    name: 'dealerAddress',
    type: 'string',
    required: true,
  },
  {
    label: 'Disclaimer Text',
    step: PDFFlyerStep.CONTENT,
    name: 'disclaimer',
    type: 'string',
  },
  {
    label: 'Show Authorised Distributor Logo',
    step: PDFFlyerStep.CONTENT,
    name: 'showAuthDistLogo',
    type: 'boolean',
  },
  {
    label: 'Order Products',
    step: PDFFlyerStep.ORDER,
    name: 'orderProducts',
    type: 'orderProducts',
    required: true, // Required based on if order has been visited
  },
  {
    label: 'Products',
    step: PDFFlyerStep.PRODUCTS,
    name: 'products',
    type: 'products',
    required: true, // handled in the component
  },
  {
    label: 'Placeholder',
    step: PDFFlyerStep.CREATE,
    name: 'placeholder',
    type: 'placeholder', // Require filed in step to display the step
  },
];

const productFields: ProductField[] = [
  {
    label: 'Standard Price',
    name: 'standardPrice',
    type: 'formattedNumber',
    options: currencies,
    required: true,
  },
  {
    label: 'Campaign Price',
    name: 'campaignPrice',
    type: 'formattedNumber',
    options: currencies,
    required: true,
  },
  {
    label: 'Percentage Price Difference',
    name: 'percentagePriceDifference',
    type: 'formattedNumber',
    options: percentage,
    required: true,
  },
  {
    label: 'Override Product Image',
    name: 'image',
    type: 'file',
  },
  {
    label: 'Price Image',
    name: 'priceImage',
    type: 'file',
    required: true,
  },
];

export const PDFTagTemplateOptions = [
  {
    key: PDFSize.A4.toString(),
    value: 'A4 (4 x <strong>14.9 x 6.9cm)</strong>',
    id: 'tag',
    name: 'Tag',
    products: 0,
    fields: fields.filter((x) => ['cultureCode', 'template', 'headerLayout'].includes(x.name)),
  },
  {
    key: PDFSize.ReorderTags.toString(),
    value: 'Reorder Tags',
    id: 'tag',
    name: 'Tag',
    products: 0,
    fields: fields.filter((x) => ['cultureCode', 'template', 'headerLayout'].includes(x.name)),
  },
  {
    key: PDFSize.ReorderTagsTiled.toString(),
    value: 'Reorder Tags (Tiled)',
    id: 'tag',
    name: 'Tag',
    products: 0,
    fields: fields.filter((x) => ['cultureCode', 'template', 'headerLayout'].includes(x.name)),
  },
  {
    key: PDFSize.Flyer.toString(),
    value: 'A4 Flyer',
    id: 'tag',
    name: 'Tag',
    products: 0,
    fields: fields.filter((x) => ['cultureCode', 'template', 'headerLayout'].includes(x.name)),
  },
  {
    key: PDFSize.JSSA4.toString(),
    value: 'JSS A4',
    id: 'tag',
    name: 'Tag',
    products: 0,
    fields: fields.filter((x) => ['cultureCode', 'template', 'headerLayout'].includes(x.name)),
  },
  {
    key: PDFSize.JSSA4Double.toString(),
    value: 'JSS A4 (Double)',
    id: 'tag',
    name: 'Tag',
    products: 0,
    fields: fields.filter((x) => ['cultureCode', 'template', 'headerLayout'].includes(x.name)),
  },
  {
    key: PDFSize.JSSA4Triple.toString(),
    value: 'JSS A4 (Triple)',
    id: 'tag',
    name: 'Tag',
    products: 0,
    fields: fields.filter((x) => ['cultureCode', 'template', 'headerLayout'].includes(x.name)),
  },
  {
    key: PDFSize.JSS16x9.toString(),
    value: 'JSS 16x9',
    id: 'tag',
    name: 'Tag',
    products: 0,
    fields: fields.filter((x) => ['cultureCode', 'template', 'headerLayout'].includes(x.name)),
  },
];

export const PDFFlyerTemplateOptions: Template[] = [
  {
    key: '2-a4',
    value: 'A4 Flyer 2 Products',
    products: 2,

    fields: fields.filter((x) => !['headerLayout'].includes(x.name)),
    productFields: productFields,
    pdf: 'https://tti-fact-tags.s3-eu-west-2.amazonaws.com/638724438717906444.pdf',
  },
  {
    key: '3-a4',
    value: 'A4 Flyer 3 Products',
    products: 3,

    fields: fields.filter((x) => !['headerLayout'].includes(x.name)),
    productFields: productFields.filter((x) => !['priceImage'].includes(x.name)),
    pdf: 'https://tti-fact-tags.s3-eu-west-2.amazonaws.com/638724442300675117.pdf',
  },
  {
    key: '4-a4',
    value: 'A4 Flyer 4 Products',
    products: 4,

    fields: fields.filter((x) => !['headerLayout'].includes(x.name)),
    productFields: productFields.filter((x) => !['priceImage'].includes(x.name)),
    pdf: 'https://tti-fact-tags.s3-eu-west-2.amazonaws.com/638724494601453451.pdf',
  },
];
