import { Models } from '@core/types';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

interface Props {
  id: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  valid?: boolean;
  internationalPhoneNumberPrefixes: Models.CultureNumber[];
  required?: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InternationalPhoneNumber: FunctionComponent<Props> = ({
  valid,
  internationalPhoneNumberPrefixes,
  id,
  placeholder,
  required,
  onChange,
  value,
  isOpen,
  setIsOpen,
}) => {
  const [prefix, setPrefix] = useState<Models.CultureNumber>();
  const [number, setNumber] = useState<string>(value ? value.split('|')[1] : '');
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    formatAndFireOnChange();
  }, [number, prefix]);

  const formatAndFireOnChange = (): void => {
    onChange(`${prefix?.dialingCode}|${number}`);
  };

  useEffect(() => {
    if (value) {
      const split = value.split('|');
      const search = internationalPhoneNumberPrefixes.find((x) => x.dialingCode === split[0]);
      if (search) {
        setPrefix(search);
      }
    } else {
      setPrefix(internationalPhoneNumberPrefixes[0]);
    }
  }, [value]);

  const items = useMemo(() => {
    if (search) {
      return internationalPhoneNumberPrefixes.filter((x) => x.cultureName.toLowerCase().startsWith(search.trim().toLowerCase()));
    }

    return internationalPhoneNumberPrefixes;
  }, [search]);

  return (
    <div
      className={`form__row {!valid ? 'invalid' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="form__control">
        <div id={`${id}_dropdown`} className={`form__dropdown ${isOpen ? 'active' : ''}`}>
          {prefix && (
            <div
              className="form__dropdown-selected"
              onClick={() => setIsOpen(!isOpen)}
              dangerouslySetInnerHTML={{ __html: prefix.dialingCode }}
            />
          )}
          <div className="form__dropdown-values">
            <input
              className="form__dropdown--search form__control"
              type="text"
              placeholder="Filter...."
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
            {items.map((x) => (
              <div
                key={x.cultureCode}
                className="form__dropdown-value"
                onClick={() => {
                  setSearch('');
                  setPrefix(x);
                  setIsOpen(false);
                }}
                dangerouslySetInnerHTML={{ __html: `${x.dialingCode} - ${x.cultureName} (${x.cultureCode})` }}
              ></div>
            ))}
          </div>
        </div>
        <input
          type={'tel'}
          id={`${id}_input`}
          placeholder={placeholder}
          className="form__control"
          value={number}
          onChange={(e) => {
            setNumber(e.currentTarget.value);
          }}
        />
        <label htmlFor={`${id}_input`} className="form__label">
          {`${placeholder} ${required ? '*' : ''}`}
        </label>
      </div>
    </div>
  );
};

export default InternationalPhoneNumber;
