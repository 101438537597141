import React, { FunctionComponent, useState, useRef } from 'react';

import { usePassportContext } from '@tti/passport';
import { ApiService } from '@core/services';
import { Method } from '@core/types/api';

interface Props {
  id: string;
  value?: string;
  placeholder?: string;
  onChange: (imageUrl?: string) => void;
  required?: boolean;
  valid?: boolean;
  acceptedTypes?: string[];
}

const ImageUploader: FunctionComponent<Props> = ({
  id,
  value = null,
  placeholder = 'Upload file',
  onChange,
  required = false,
  valid = true,
  acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'],
}) => {
  const { passportContext } = usePassportContext();
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const isFileTypeValid = (file?: File): boolean => {
    return file ? acceptedTypes.includes(file.type) : false;
  };

  const uploadImage = async (file: File): Promise<string | null> => {
    if (!file) return null;

    try {
      if (!passportContext.bearerToken) {
        return null;
      }

      const response = await ApiService.request<{ imageUrl: string }>({
        method: Method.POST,
        controller: 'project',
        slug: 'upload-image',
        bearerToken: passportContext.bearerToken,
        isMultipartFormData: true,
        files: [file],
      });

      if (response && response.success && response.data?.imageUrl) {
        return response.data.imageUrl;
      }

      throw new Error('Image upload failed.');
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Failed to upload image. Please try again.');

      return null;
    }
  };

  const handleFileChange = async (file?: File) => {
    if (file && !isFileTypeValid(file)) {
      setError(`Invalid file type. Please upload one of the following: ${acceptedTypes.join(', ')}`);
      return;
    }

    setError(null);

    onChange(file ? (await uploadImage(file)) || undefined : undefined);
    resetFileInput();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    handleFileChange(file ? file : undefined);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files?.[0] || null;
    handleFileChange(file);
  };

  const handleRemoveImage = () => {
    onChange(undefined);
    resetFileInput();
  };

  // Fixes bug when we upload the same file twice
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className={`form__row ${!valid ? 'invalid' : ''}`}>
      <div
        id={id}
        className={`image-uploader ${isDragging ? 'image-uploader--dragging' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        {!value ? (
          <span className="image-uploader__placeholder">{`${placeholder} ${required ? '*' : ''}`}</span>
        ) : (
          <img src={value} alt="Uploaded preview" className="image-uploader__img" />
        )}
      </div>
      <input
        type="file"
        id={`${id}-input`}
        className="image-uploader__input"
        accept={acceptedTypes.join(',')}
        onChange={handleInputChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      {error && <div className="image-uploader__error">{error}</div>}
      {value && (
        <button type="button" className="btn image-uploader__remove" onClick={handleRemoveImage}>
          Remove Image
        </button>
      )}
      <label htmlFor={id} className="form__label">
        {`${placeholder} ${required ? '*' : ''}`}
      </label>
    </div>
  );
};

export default ImageUploader;
