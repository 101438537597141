import { Api } from '@core/types';
import { IClearProject } from '../shared';
import { ProjectEnums } from '@core/enums';

export const UPDATE_PROJECT = 'PROJECTS/UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'PROJECTS/UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'PROJECTS/UPDATE_PROJECT_ERROR';

export const FETCH_PROJECT = 'PROJECTS/FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS/FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECT_ERROR = 'PROJECTS/FETCH_PROJECT_ERROR';

export const FETCH_PROJECTS = 'PROJECTS/FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'PROJECTS/FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_ERROR = 'PROJECTS/FETCH_PROJECTS_ERROR';

export const CREATE_PROJECT = 'PROJECTS/CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'PROJECTS/CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_ERROR = 'PROJECTS/CREATE_PROJECT_ERROR';

export const DELETE_PROJECT = 'PROJECTS/DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'PROJECTS/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'PROJECTS/DELETE_PROJECT_ERROR';

export const FETCH_USER_PDFS = 'PROJECTS/FETCH_USER_PDFS';
export const FETCH_USER_PDFS_SUCCESS = 'PROJECTS/FETCH_USER_PDFS_SUCCESS';
export const FETCH_USER_PDFS_ERROR = 'PROJECTS/FETCH_USER_PDFS_ERROR';

export const SET_PRODUCT_QUANTITIES = 'PROJECTS/SET_PRODUCT_QUANTITIES';

export interface IFetchProjects {
  type: typeof FETCH_PROJECTS;
}

export interface IFetchProjectsSuccess {
  type: typeof FETCH_PROJECTS_SUCCESS;
  payload: Api.IFetchProjectsResponse;
}

export interface IFetchProjectsError {
  type: typeof FETCH_PROJECTS_ERROR;
  payload: Api.IApiError[];
}

export interface IFetchProject {
  type: typeof FETCH_PROJECT;
}

export interface IFetchProjectSuccess {
  type: typeof FETCH_PROJECT_SUCCESS;
  payload: Api.IFetchProjectResponse;
}

export interface IFetchProjectError {
  type: typeof FETCH_PROJECT_ERROR;
  payload: Api.IApiError[];
}

export interface ICreateProject {
  type: typeof CREATE_PROJECT;
}

export interface ICreateProjectSuccess {
  type: typeof CREATE_PROJECT_SUCCESS;
  payload: Api.ICreateProjectRequest;
}

export interface ICreateProjectError {
  type: typeof CREATE_PROJECT_ERROR;
  payload: Api.IApiError[];
}

export interface IDeleteProject {
  type: typeof DELETE_PROJECT;
}

export interface IDeleteProjectSuccess {
  type: typeof DELETE_PROJECT_SUCCESS;
}

export interface IDeleteProjectError {
  type: typeof DELETE_PROJECT_ERROR;
  payload: Api.IApiError[];
}

export interface IAddProducts {
  type: typeof UPDATE_PROJECT;
}

export interface IAddProductsSuccess {
  type: typeof UPDATE_PROJECT_SUCCESS;
  payload: Api.IUpdateProjectRequest;
}

export interface IAddProductsError {
  type: typeof UPDATE_PROJECT_ERROR;
  payload: Api.IApiError[];
}

export interface IFetchUserPdfs {
  type: typeof FETCH_USER_PDFS;
  payload: ProjectEnums.PrinterApprovalStatus;
}

export interface IFetchUserPdfsSuccess {
  type: typeof FETCH_USER_PDFS_SUCCESS;
  payload: {
    response: Api.IFetchPdfsResponse;
    status?: ProjectEnums.PrinterApprovalStatus;
  };
}

export interface IFetchUserPdfsError {
  type: typeof FETCH_USER_PDFS_ERROR;
  payload: ProjectEnums.PrinterApprovalStatus;
}

export interface ISetProductQuantities {
  type: typeof SET_PRODUCT_QUANTITIES;
  payload: {
    increment: boolean;
    ids: number[];
  };
}

export type ProjectActionTypes =
  | IFetchProject
  | IFetchProjectSuccess
  | IFetchProjectError
  | IFetchProjects
  | IFetchProjectsSuccess
  | IFetchProjectsError
  | ICreateProject
  | ICreateProjectError
  | ICreateProjectSuccess
  | IDeleteProject
  | IDeleteProjectSuccess
  | IDeleteProjectError
  | IAddProducts
  | IAddProductsSuccess
  | IAddProductsError
  | IClearProject
  | IFetchUserPdfs
  | IFetchUserPdfsError
  | IFetchUserPdfsSuccess
  | ISetProductQuantities;
