import React, { FunctionComponent, Fragment } from 'react';
import { usePassportContext } from '@tti/passport';
import { Api, Models, States } from '@core/types';
import { connect } from 'react-redux';
import { Modal } from '@components/shared';
import { PassportEnums } from '@tti/passport';
import PdfPrintSettings from './PdfPrintSettings';
import { ProjectEnums } from '@core/enums';
import { PDFItemType, PDFType } from '@core/enums/project';
import PdfDownloadSettings from './PdfDownloadSettings';
import { PDFProps } from '@core/types/api';
import { pdfSettingsActionCreators } from '@core/redux/pdfSettings';
import { FormValue } from '@core/types/models';

interface IProps {
  app?: States.IAppState;
  project?: States.IProjectState;
  pdfSettings: States.IPdfSettingsState;
  product: States.IProductState;
  generatePDF: (params: Api.IUpdateFlyerAndGeneratePDFRequest, type: PDFType) => void;
  sendForApproval: (params: Api.ISendForApprovalRequest, projectGuid: string, userCultures: string[]) => void;
  setPDFModal: (params: Models.SetPDFModal) => void;
  setOrderDetails: (formData: Record<string, string> | null) => void;
  downloadPDF: (url: string) => void;
  clearPDF: (type: ProjectEnums.PDFType) => void;
  setPdfItemType: (pdfItemType: PDFItemType | null) => void;
  setCurrency: (currency: string) => void;
  setField: (field: string, value: FormValue) => void;
}

const ProjectDetailFooter: FunctionComponent<IProps> = ({
  app,
  project,
  pdfSettings,
  product,
  generatePDF,
  sendForApproval,
  setPDFModal,
  clearPDF,
  downloadPDF,
  setOrderDetails,
  setPdfItemType,
  setCurrency,
  setField,
}) => {
  const { passportContext, getClaim, getClaims } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const allCultureClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter((x) =>
    x.value.startsWith(PassportEnums.RoleType.SentToPrintApprover),
  );

  const handleShowGeneratePdf = (type: ProjectEnums.PDFType) => {
    setPDFModal({
      isOpen: true,
      pdfType: type,
    });
  };

  const handleCloseGeneratePdf = (type: ProjectEnums.PDFType) => {
    // Reset the PDF modal
    setPDFModal({
      pdfType: type,
      isOpen: false,
      showConfirmation: false,
    });
    setPdfItemType(null);
  };

  const handleGeneratePDF = async (props: PDFProps) => {
    if (!project || project.currentProject === null || !passportContext.bearerToken) {
      return;
    }

    let orderDetails;
    const modal = pdfSettings.printPDF.modal;
    if (modal.orderDetails !== null) {
      orderDetails = {
        orderNumber: modal.orderDetails['orderNumber'],
        orderCompanyName: modal.orderDetails['orderCompanyName'],
        orderFirstName: modal.orderDetails['orderFirstName'],
        orderLastName: modal.orderDetails['orderLastName'],
        orderEmailAddress: modal.orderDetails['orderEmailAddress'],
        orderAddressLine1: modal.orderDetails['orderAddressLine1'],
        orderAddressLine2: modal.orderDetails['orderAddressLine2'],
        orderCity: modal.orderDetails['orderCity'],
        orderPostcode: modal.orderDetails['orderPostcode'],
        orderCountry: modal.orderDetails['orderCountry'],
        orderNotes: modal.orderDetails['orderNotes'],
        invoicedCountry: modal.orderDetails['invoicedCountry'],
      };
    }

    generatePDF(
      {
        bearerToken: passportContext.bearerToken,
        projectGUID: project.currentProject.projectGUID,
        flyerData: props,
        ...orderDetails,
      },
      props.type,
    );
  };

  if (!project || project.currentProject === null || project.currentProject.products.length === 0 || !app) {
    return null;
  }

  const handleDownload = () => {
    if (!pdfSettings.downloadPDF.projectPdf) {
      clearPDF(ProjectEnums.PDFType.Download);
      return;
    }

    downloadPDF(pdfSettings.downloadPDF.projectPdf.pdfUrl);
  };

  const handleSendToApproval = () => {
    if (
      !project ||
      project.currentProject === null ||
      !passportContext.bearerToken ||
      !cultureClaim ||
      !pdfSettings.printPDF.projectPdf
    ) {
      return;
    }

    const userCultures = allCultureClaims.map((x) => {
      // example: role.ftg.sent-to-print-approver.en-gb
      // Get the last index of '.'
      const index = x.value.lastIndexOf('.');

      // + 1 to skip the last '.' and return the culture code
      return x.value.substring(index + 1, x.value.length);
    });

    sendForApproval(
      {
        bearerToken: passportContext.bearerToken,
        cultureCode: cultureClaim.value,
        projectPDFID: pdfSettings.printPDF.projectPdf.projectPDFID,
      },
      project.currentProject.projectGUID,
      userCultures,
    );
  };

  const handleModalClose = (type: PDFType) => {
    handleCloseGeneratePdf(type);
    setPdfItemType(null);
  };

  return (
    <Fragment>
      <Modal visible={pdfSettings.printPDF.modal.isOpen} onClose={() => handleModalClose(PDFType.SendToPrinters)} showClose={true}>
        <PdfPrintSettings
          app={app}
          project={project}
          pdfSettings={pdfSettings}
          product={product}
          handleGeneratePDF={handleGeneratePDF}
          onSubmit={handleSendToApproval}
          setOrderDetails={setOrderDetails}
          onClose={() => handleCloseGeneratePdf(PDFType.SendToPrinters)}
          setPdfItemType={setPdfItemType}
          setCurrency={setCurrency}
          setField={setField}
        />
      </Modal>
      <Modal visible={pdfSettings.downloadPDF.modal.isOpen} onClose={() => handleModalClose(PDFType.Download)} showClose={true}>
        <PdfDownloadSettings
          app={app}
          project={project}
          pdfSettings={pdfSettings}
          product={product}
          handleGeneratePDF={handleGeneratePDF}
          onSubmit={handleDownload}
          onClose={() => handleCloseGeneratePdf(PDFType.Download)}
          setPdfItemType={setPdfItemType}
          setCurrency={setCurrency}
          setField={setField}
        />
      </Modal>

      <div className="selected-products__footer">
        <button
          className="btn btn--inline selected-products__download"
          onClick={() => handleShowGeneratePdf(ProjectEnums.PDFType.Download)}
        >
          Download
        </button>
        <button
          className="btn btn--inline selected-products__download"
          onClick={() => handleShowGeneratePdf(ProjectEnums.PDFType.SendToPrinters)}
        >
          Send To Printers
        </button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  app: state.app,
  project: state.project,
  pdfSettings: state.pdfSettings,
  product: state.product,
});

const mapDispatchToProps = {
  generatePDF: (params: Api.IUpdateFlyerAndGeneratePDFRequest, type: PDFType) => pdfSettingsActionCreators.generatePDF(params, type),
  sendForApproval: (params: Api.ISendForApprovalRequest, projectGuid: string, userCultures: string[]) =>
    pdfSettingsActionCreators.SendForApproval(params, projectGuid, userCultures),
  setPDFModal: (params: Models.SetPDFModal) => pdfSettingsActionCreators.setPDFModal(params),
  downloadPDF: (url: string) => pdfSettingsActionCreators.downloadPDF(url),
  setOrderDetails: (formData: Record<string, string> | null) => pdfSettingsActionCreators.setOrderDetails(formData),
  clearPDF: (type: ProjectEnums.PDFType) => pdfSettingsActionCreators.clearPDF(type),
  setPdfItemType: (pdfItemType: PDFItemType | null) => pdfSettingsActionCreators.setPdfItemType(pdfItemType),
  setCurrency: (currency: string) => pdfSettingsActionCreators.setCurrency(currency),
  setField: (field: string, value: FormValue) => pdfSettingsActionCreators.setField(field, value),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailFooter);
