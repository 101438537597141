import { States, Api } from '@core/types';
import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR,
  ProjectActionTypes,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  FETCH_PROJECT_ERROR,
  FETCH_PROJECT,
  FETCH_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  FETCH_USER_PDFS,
  FETCH_USER_PDFS_ERROR,
  FETCH_USER_PDFS_SUCCESS,
} from './actions';
import { CLEAR_PROJECT } from '../shared';
import { ProjectEnums } from '@core/enums';

const initialState: States.IProjectState = {
  isCreating: false,
  isLoading: false,
  isLoadingProject: false,
  isDeleting: false,
  isAddingProducts: false,
  projects: [],
  currentProject: null,
  approvedPDFs: {
    isLoading: false,
    pdfs: [],
  },
  rejectedPDFs: {
    isLoading: false,
    pdfs: [],
  },
  errors: [],
};

function projectReducer(state: States.IProjectState = initialState, action: ProjectActionTypes): States.IProjectState {
  switch (action.type) {
    case FETCH_PROJECTS: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    case FETCH_PROJECTS_SUCCESS: {
      const newState = { ...state };

      newState.isLoading = false;
      newState.projects = action.payload.projects;

      return newState;
    }

    case FETCH_PROJECT: {
      const newState = { ...state };

      newState.isLoadingProject = true;

      return newState;
    }

    case FETCH_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.currentProject = action.payload.project;
      newState.isLoadingProject = false;

      return newState;
    }

    case UPDATE_PROJECT_ERROR:
    case FETCH_PROJECT_ERROR:
    case DELETE_PROJECT_ERROR:
    case CREATE_PROJECT_ERROR:
    case FETCH_PROJECTS_ERROR: {
      const newState = { ...state };

      newState.isLoading = false;
      newState.isLoadingProject = false;
      newState.isDeleting = false;
      newState.errors = action.payload;

      return newState;
    }

    case CREATE_PROJECT: {
      const newState = { ...state };

      newState.isCreating = true;

      return newState;
    }

    case CREATE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isCreating = false;

      return newState;
    }

    case DELETE_PROJECT: {
      const newState = { ...state };

      newState.isDeleting = true;

      return newState;
    }

    case CLEAR_PROJECT: {
      const newState = { ...state };

      newState.currentProject = null;

      return newState;
    }

    case DELETE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isDeleting = false;

      return newState;
    }

    case UPDATE_PROJECT: {
      const newState = { ...state };

      newState.isAddingProducts = true;

      return newState;
    }

    case UPDATE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isAddingProducts = false;

      if (newState.currentProject !== null) {
        newState.currentProject.products = action.payload.projectProducts;
      }

      return newState;
    }

    case FETCH_USER_PDFS: {
      const newState = { ...state };

      if (action.payload === ProjectEnums.PrinterApprovalStatus.Approved) {
        newState.approvedPDFs.isLoading = true;
      }

      if (action.payload === ProjectEnums.PrinterApprovalStatus.Rejected) {
        newState.rejectedPDFs.isLoading = true;
      }

      return newState;
    }

    case FETCH_USER_PDFS_SUCCESS: {
      const newState = { ...state };

      if (action.payload.status === ProjectEnums.PrinterApprovalStatus.Approved) {
        newState.approvedPDFs.pdfs = [...action.payload.response.projectPDFs];
      }

      if (action.payload.status === ProjectEnums.PrinterApprovalStatus.Rejected) {
        newState.rejectedPDFs.pdfs = [...action.payload.response.projectPDFs];
      }

      return newState;
    }

    case FETCH_USER_PDFS_ERROR: {
      const newState = { ...state };

      if (action.payload === ProjectEnums.PrinterApprovalStatus.Approved) {
        newState.approvedPDFs.isLoading = true;
      }

      if (action.payload === ProjectEnums.PrinterApprovalStatus.Rejected) {
        newState.rejectedPDFs.isLoading = true;
      }

      return newState;
    }

    default:
      return state;
  }
}

export default projectReducer;
