import { Dispatch, AnyAction } from 'redux';
import {
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  FETCH_PROJECT,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_ERROR,
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  FETCH_USER_PDFS,
  FETCH_USER_PDFS_SUCCESS,
  FETCH_USER_PDFS_ERROR,
} from './actions';
import { ApiService } from '@core/services';
import { Api } from '@core/types';
import { ThunkDispatch } from 'redux-thunk';
import { CLEAR_PROJECT } from '../shared';
import { fetchProjectProducts } from '../products/actioncreators';
import { push } from 'connected-react-router';
import { Routes } from '@components/routing';
import { PDFType } from '@core/enums/project';
import { clearPDF, resetPDF } from '../pdfSettings/actioncreators';

export const fetchProject = (params: Api.IFetchProjectRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_PROJECT,
  });

  // Do the api request
  const response = await ApiService.request<Api.IFetchProjectResponse>({
    controller: 'project',
    method: Api.Method.GET,
    slug: 'get-project',
    bearerToken: params.bearerToken,
    params: {
      projectGUID: params.projectGUID,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    dispatch({
      type: FETCH_PROJECT_ERROR,
      payload: response.errors,
    });

    return dispatch(
      push({
        pathname: Routes.home.path,
      }),
    );
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_PROJECT_SUCCESS,
    payload: response.data,
  });
};

export const fetchProjects = (params: Api.IBaseAuthorisedRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_PROJECTS,
  });

  // Do the api request
  const response = await ApiService.request<Api.IFetchProjectsResponse>({
    controller: 'project',
    method: Api.Method.GET,
    slug: 'list-projects',
    bearerToken: params.bearerToken,
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: FETCH_PROJECTS_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_PROJECTS_SUCCESS,
    payload: response.data,
  });
};

export const createProject = (params: Api.ICreateProjectRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: CREATE_PROJECT,
  });

  // Do the api request
  const response = await ApiService.request<Api.ICreateProjectResponse>({
    controller: 'project',
    method: Api.Method.POST,
    slug: 'create-project',
    bearerToken: params.bearerToken,
    params: {
      projectName: params.projectName,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: CREATE_PROJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the response of the creation success
  dispatch({
    type: CREATE_PROJECT_SUCCESS,
    payload: response.data,
  });

  return dispatch(
    push({
      pathname: `${Routes.project.slug}${response.data?.projectGUID}`,
    }),
  );
};

export const deleteProject = (params: Api.IDeleteProjectRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: DELETE_PROJECT,
  });

  // Do the api request
  const response = await ApiService.request<boolean | null>({
    controller: 'project',
    method: Api.Method.DELETE,
    slug: 'delete-project',
    bearerToken: params.bearerToken,
    params: {
      projectGUID: params.projectGUID,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: DELETE_PROJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the response of the deletion success
  dispatch({
    type: DELETE_PROJECT_SUCCESS,
    payload: response.data,
  });

  // Refetch the products
  return dispatch(fetchProjects({ bearerToken: params.bearerToken }));
};

export const updateProjectError = (errors: Api.IApiError[]) => {
  return {
    type: UPDATE_PROJECT_ERROR,
    payload: errors,
  };
};

export const updateProject = (params: Api.IUpdateProjectRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: UPDATE_PROJECT,
  });

  // Do the api request
  const response = await ApiService.request<boolean | null>({
    controller: 'project',
    method: Api.Method.PUT,
    slug: 'update-project-products',
    bearerToken: params.bearerToken,
    params: {
      projectGUID: params.projectGUID,
      projectProducts: params.projectProducts,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: UPDATE_PROJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch a success
  dispatch({
    type: UPDATE_PROJECT_SUCCESS,
    payload: params,
  });

  resetPDF();

  // Clear any PDF data and reset the modals
  dispatch(clearPDF(PDFType.Download));
  dispatch(clearPDF(PDFType.SendToPrinters));

  if (params.refetchProject === true) {
    // Refetch the project products
    const variantAgilityIDs = params.projectProducts.map((x) => x.productID);

    dispatch(fetchProjectProducts({ bearerToken: params.bearerToken, cultureCode: params.cultureCode, variantAgilityIDs }));
  }
};

export function clearProject() {
  return {
    type: CLEAR_PROJECT,
  };
}

export const fetchUserPdfs = (params: Api.IFetchUserPdfsRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_USER_PDFS,
    payload: params.status,
  });

  // Do the api request
  const response = await ApiService.request<Api.IFetchUserPdfsResponse>({
    controller: 'project',
    method: Api.Method.GET,
    slug: 'list-user-pdfs',
    bearerToken: params.bearerToken,
    params: {
      status: params.status,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: FETCH_USER_PDFS_ERROR,
      payload: params.status,
    });
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_USER_PDFS_SUCCESS,
    payload: {
      response: response.data,
      status: params.status,
    },
  });
};
