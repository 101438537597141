import React from 'react';
import PdfSettings, { IPdfSettingsProps } from './PdfSettings';
import { PDFType } from '@core/enums/project';

interface IProps extends Omit<IPdfSettingsProps, 'sizes' | 'type'> {}

const PdfDownloadSettings: React.FunctionComponent<IProps> = ({
  app,
  project,
  pdfSettings,
  product,
  handleGeneratePDF,
  onClose,
  onSubmit,
  setPdfItemType,
  setCurrency,
  setField,
}) => {
  return (
    <PdfSettings
      project={project}
      app={app}
      pdfSettings={pdfSettings}
      product={product}
      onClose={onClose}
      type={PDFType.Download}
      handleGeneratePDF={handleGeneratePDF}
      onSubmit={onSubmit}
      setPdfItemType={setPdfItemType}
      setCurrency={setCurrency}
      setField={setField}
    />
  );
};

export default PdfDownloadSettings;
