import React, { Fragment, useState, FunctionComponent } from 'react';
import Textbox from '@components/shared/Textbox';
import { Dropdown } from '@components/shared';
import { Models } from '@core/types';
import InternationalPhoneNumber from '@components/shared/InternationalPhoneNumber';

interface Props {
  formData: Record<string, string> | null;
  hasSubmit: boolean;
  countries: Models.Country[];
  invoiceCountries: Models.Country[];
  internationalPhoneNumberPrefixes: Models.CultureNumber[];
  onSubmit: (formData: Record<string, string>) => void;
}

const ProjectDetailsForm: FunctionComponent<Props> = ({ onSubmit, countries, invoiceCountries, internationalPhoneNumberPrefixes }) => {
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [invalidFields, setInvalidFields] = useState<Array<string>>([]);
  const requiredFields: Array<string> = [
    'orderNumber',
    'orderEmailAddress',
    'orderCompanyName',
    'orderFirstName',
    'orderLastName',
    'orderAddressLine1',
    'orderCity',
    'orderPostcode',
    'invoicedCountry',
    'orderCountry',
  ];
  const invalidValues: Array<any> = ['', undefined, null];

  const dropdownCountries = countries
    .map((x) => ({
      key: x.countryName,
      value: x.countryName,
    }))
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

  const dropdownInvoiceCountries = invoiceCountries
    .map((x) => ({
      key: x.countryName,
      value: x.countryName,
    }))
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

  const onChange = (value: string, fieldName: string) => {
    const newFormData = { ...formData };

    newFormData[fieldName] = value;

    setFormData(newFormData);
  };

  const isRequired = (id: string): boolean => requiredFields.includes(id);
  const isValid = (id: string): boolean => !invalidFields.includes(id);

  const validate = (): void => {
    if (requiredFields.every((requiredField) => !invalidValues.includes(formData[requiredField]))) {
      onSubmit(formData);
    } else {
      let invalidFields = requiredFields.reduce((acc: Array<string>, cur: string) => {
        if (invalidValues.includes(formData[cur])) acc.push(cur);
        return acc;
      }, []);

      setInvalidFields(invalidFields);
    }
  };

  const [internationalPhoneNumberDropdownOpen, setInternationalPhoneNumberDropdownOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <div className="form__inner">
        <div
          className="form__fields form__fields--split"
          onClick={() => {
            setInternationalPhoneNumberDropdownOpen(false);
          }}
        >
          <h2 className="align--center">Order Details</h2>
          <p className="align--center">
            Provide additional information for your print request below. This information will be supplied along with your fact tags.
          </p>

          <Textbox
            id="orderNumber"
            value={formData['orderNumber']}
            placeholder="Order Number"
            onChange={(value) => onChange(value, 'orderNumber')}
            required={isRequired('orderNumber')}
            valid={isValid('orderNumber')}
          />
          <Textbox
            id="orderEmailAddress"
            value={formData['orderEmailAddress']}
            placeholder="TTi Requester Email Address"
            onChange={(value) => onChange(value, 'orderEmailAddress')}
            required={isRequired('orderEmailAddress')}
            valid={isValid('orderEmailAddress')}
          />
          <Textbox
            id="orderCompanyName"
            value={formData['orderCompanyName']}
            placeholder="Company Name"
            onChange={(value) => onChange(value, 'orderCompanyName')}
            required={isRequired('orderCompanyName')}
            valid={isValid('orderCompanyName')}
          />

          <InternationalPhoneNumber
            id="orderRecipientNumber"
            value={formData['orderRecipientNumber']}
            placeholder="Recipient Telephone"
            onChange={(value) => onChange(value, 'orderRecipientNumber')}
            required={isRequired('orderRecipientNumber')}
            valid={isValid('orderRecipientNumber')}
            internationalPhoneNumberPrefixes={internationalPhoneNumberPrefixes}
            isOpen={internationalPhoneNumberDropdownOpen}
            setIsOpen={setInternationalPhoneNumberDropdownOpen}
          />

          <Textbox
            id="orderFirstName"
            value={formData['orderFirstName']}
            placeholder="First Name"
            onChange={(value) => onChange(value, 'orderFirstName')}
            required={isRequired('orderFirstName')}
            valid={isValid('orderFirstName')}
          />
          <Textbox
            id="orderLastName"
            value={formData['orderLastName']}
            placeholder="Last Name"
            onChange={(value) => onChange(value, 'orderLastName')}
            required={isRequired('orderLastName')}
            valid={isValid('orderLastName')}
          />
          <Textbox
            id="orderAddressLine1"
            value={formData['orderAddressLine1']}
            placeholder="Address Line 1"
            onChange={(value) => onChange(value, 'orderAddressLine1')}
            required={isRequired('orderAddressLine1')}
            valid={isValid('orderAddressLine1')}
          />
          <Textbox
            id="orderAddressLine2"
            value={formData['orderAddressLine2']}
            placeholder="Address Line 2"
            onChange={(value) => onChange(value, 'orderAddressLine2')}
            required={isRequired('orderAddressLine2')}
            valid={isValid('orderAddressLine2')}
          />
          <Textbox
            id="orderCity"
            value={formData['orderCity']}
            placeholder="City"
            onChange={(value) => onChange(value, 'orderCity')}
            required={isRequired('orderCity')}
            valid={isValid('orderCity')}
          />
          <Textbox
            id="orderPostCode"
            value={formData['orderPostcode']}
            placeholder="Post Code"
            onChange={(value) => onChange(value, 'orderPostcode')}
            required={isRequired('orderPostcode')}
            valid={isValid('orderPostcode')}
          />
          <Dropdown
            id="invoicedCountry"
            placeholder="Invoiced Country..."
            onChange={(value) => onChange(value, 'invoicedCountry')}
            options={dropdownInvoiceCountries}
            value={formData['invoicedCountry']}
            includeSelectedItem={true}
            required={isRequired('invoicedCountry')}
            valid={isValid('invoicedCountry')}
          />
          <Dropdown
            id="orderCountry"
            placeholder="Country..."
            onChange={(value) => onChange(value, 'orderCountry')}
            options={dropdownCountries}
            value={formData['orderCountry']}
            includeSelectedItem={true}
            required={isRequired('orderCountry')}
            valid={isValid('orderCountry')}
          />
          <Textbox
            id="orderNotes"
            value={formData['orderNotes']}
            placeholder="Notes"
            multiLine={true}
            onChange={(value) => onChange(value, 'orderNotes')}
            required={isRequired('orderNotes')}
            valid={isValid('orderNotes')}
          />
        </div>
      </div>
      {invalidFields.length > 0 && (
        <p className="form__validation-message">
          Some fields have invalid values.
          <br />
          Please correct any highlighted fields and try again.
        </p>
      )}
      <button className="btn" type="button" onClick={() => validate()}>
        Create PDF
      </button>
    </Fragment>
  );
};

export default ProjectDetailsForm;
