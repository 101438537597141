import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
  id: string;
  value: boolean;
  label: string;
  required?: boolean;
  onClick: () => void;
}

const Checkbox: FunctionComponent<Props> = ({ id, label, required, value, onClick }) => {
  const checkboxClassNames = classNames({
    form__checkbox: true,
    active: value,
  });

  return (
    <div className="form__row" onClick={onClick} id={id}>
      <div className={checkboxClassNames}>
        {label} {required ? '*' : ''}
        {value}
      </div>
    </div>
  );
};

export default Checkbox;
