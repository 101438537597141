import { Dispatch, AnyAction } from 'redux';
import { PDFItemType, PrinterApprovalStatus, PDFType } from '@core/enums/project';
import ApiService from '@core/services/ApiService';
import { Api } from '@core/types';
import { ThunkDispatch } from 'redux-thunk';
import { FormValue, SetPDFModal } from '@core/types/models';
import {
  GENERATE_PDF,
  GENERATE_PDF_SUCCESS,
  SEND_FOR_APPROVAL,
  SEND_FOR_APPROVAL_SUCCESS,
  SEND_FOR_APPROVAL_ERROR,
  SET_PDF_MODAL,
  CLEAR_PDF,
  DOWNLOAD_PDF,
  SET_ORDER_DETAILS,
  SET_ITEM_TYPE,
  FETCH_PROJECT_PDF,
  FETCH_PROJECT_PDF_SUCCESS,
  FETCH_PROJECT_PDF_ERROR,
  RESET_PDF,
  PdfSettingsActionTypes,
  SET_CURRENCY,
  SET_FIELD,
} from './actions';
import { fetchPdfs } from '../admin/actioncreators';
import { updateProjectError } from '../projects/actioncreators';
import { IGeneratePDFRequest, IUpdateFlyerDataRequest } from '@core/types/api';

const fetchPdf = (params: Api.IFetchProjectPdfRequest) =>
  new Promise<Api.IBaseResponse<Api.IFetchProjectPdfResponse>>(async (resolve) => {
    const handle = window.setInterval(async () => {
      const response = await ApiService.request<Api.IFetchProjectPdfResponse>({
        controller: 'project',
        method: Api.Method.GET,
        slug: 'get-project-pdf',
        bearerToken: params.bearerToken,
        params: {
          projectPDFID: params.projectPDFID,
        },
      });

      if (response.data?.projectPDF !== null && response.data?.projectPDF.printerAppovalStatus !== PrinterApprovalStatus.Generating) {
        window.clearTimeout(handle);
        resolve(response);
        return;
      }
    }, 3000);
  });

export const fetchProjectPdf = (params: Api.IFetchProjectPdfRequest) => async (dispatch: Dispatch) =>
  new Promise(async (resolve) => {
    // Initial action to trigger loaders
    dispatch({
      type: FETCH_PROJECT_PDF,
    });

    // Do the api request
    const response = await fetchPdf(params);

    // Dispatch any errors
    if (!response.success || response.errors.length > 0) {
      return dispatch({
        type: FETCH_PROJECT_PDF_ERROR,
        payload: response.errors,
      });
    }

    // dispatch the final response data
    return dispatch({
      type: FETCH_PROJECT_PDF_SUCCESS,
      payload: response.data,
    });
  });

export const generatePDF =
  (params: Api.IUpdateFlyerAndGeneratePDFRequest, type: PDFType) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: GENERATE_PDF,
      payload: type,
    });

    const generateUUID = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    };

    const { flyerData, ...remainingParams } = params;

    let flyerGUID = null;
    let generatePdfResponse = null;

    if (params.flyerData.itemType === PDFItemType.Flyer) {
      flyerGUID = params.flyerGUID || generateUUID();

      generatePdfResponse = await ApiService.request<Api.IGeneratePDFResponse>({
        controller: 'project',
        method: Api.Method.POST,
        slug: 'update-flyer-data',
        bearerToken: params.bearerToken,
        params: {
          flyerGUID,
          projectGUID: params.projectGUID,
          flyerData: JSON.stringify({
            ...params.flyerData,
            template: params.flyerData.template.key,
            heroImage: undefined,
            features: undefined,
          }),
          flyerProducts:
            params.flyerData.products
              ?.map((x) => x.variantAgilityID)
              .filter((x) => x !== null)
              .slice(0, params.flyerData.template.products) || [],
          cultureCode: params.flyerData.formData['cultureCode'],
        } as IUpdateFlyerDataRequest,
      });

      if (!generatePdfResponse.success || generatePdfResponse.errors.length > 0) {
        updateProjectError(generatePdfResponse.errors);
        return;
      }
    } else if (params.flyerData.itemType === PDFItemType.Tags) {
      // Do the api request
      generatePdfResponse = await ApiService.request<Api.IGeneratePDFResponse>({
        controller: 'project',
        method: Api.Method.POST,
        slug: 'generate-pdf-from-project',
        bearerToken: params.bearerToken,
        params: {
          ...remainingParams,
          flyerGUID: flyerGUID,
          size: params.flyerData.template.key,
          headerLayout: params.flyerData.formData['headerLayout'],
          fullDownload: type === PDFType.Download ? true : false,
          cultureCode: params.flyerData.formData['cultureCode'],
        } as IGeneratePDFRequest,
      });
    }

    // Await PDF generation
    const fetchPdfResponse = await fetchPdf({
      projectPDFID: (generatePdfResponse && generatePdfResponse.data && generatePdfResponse.data.projectPDFID) || -1,
      bearerToken: params.bearerToken,
    });

    // Dispatch any errors
    if (!fetchPdfResponse.success || fetchPdfResponse.errors.length > 0) {
      updateProjectError(fetchPdfResponse.errors);
    }

    // dispatch a success
    return dispatch({
      type: GENERATE_PDF_SUCCESS,
      payload: {
        response: fetchPdfResponse.data,
        type: type,
      },
    });
  };

export const SendForApproval =
  (params: Api.ISendForApprovalRequest, projectGUID: string, userCultures: string[]) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    // Initial action to trigger loaders
    dispatch({
      type: SEND_FOR_APPROVAL,
    });

    // Do the api request
    const response = await ApiService.request<boolean>({
      controller: 'project',
      method: Api.Method.GET,
      slug: 'send-pdf-for-approval',
      bearerToken: params.bearerToken,
      params: {
        projectPDFID: params.projectPDFID,
        cultureCode: params.cultureCode,
      },
    });

    // Dispatch any errors
    if (!response.success || response.errors.length > 0) {
      return dispatch({
        type: SEND_FOR_APPROVAL_ERROR,
        payload: response.errors,
      });
    }

    // dispatch a success
    dispatch({
      type: SEND_FOR_APPROVAL_SUCCESS,
      payload: response.data,
    });

    dispatch(clearPDF(PDFType.SendToPrinters));

    dispatch(
      fetchPdfs({
        bearerToken: params.bearerToken,
        cultureCode: userCultures,
        status: PrinterApprovalStatus.AwaitingApproval,
      }),
    );

    return dispatch(
      setPDFModal({
        isGenerating: false,
        isOpen: true,
        isSendingForApproval: false,
        showConfirmation: true,
        pdfType: PDFType.SendToPrinters,
      }),
    );
  };

export const setPdfItemType = (pdfItemType: PDFItemType | null) => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: SET_ITEM_TYPE,
    payload: pdfItemType,
  });
};

export function setOrderDetails(formData: Record<string, string> | null): PdfSettingsActionTypes {
  return {
    type: SET_ORDER_DETAILS,
    payload: formData,
  };
}

export const setPDFModal = (settings: SetPDFModal) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: SET_PDF_MODAL,
    payload: settings,
  });
};

export const clearPDF = (type: PDFType) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: CLEAR_PDF,
    payload: type,
  });

  return dispatch(
    setPDFModal({
      isGenerating: false,
      isOpen: false,
      isSendingForApproval: false,
      pdfType: type,
    }),
  );
};

export const downloadPDF = (url: string) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: DOWNLOAD_PDF,
  });

  // Download the URL using an anchor tag
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = 'FactTags.pdf';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();

  dispatch(clearPDF(PDFType.Download));

  return dispatch(
    setPDFModal({
      isGenerating: false,
      isOpen: false,
      isSendingForApproval: false,
      pdfType: PDFType.Download,
    }),
  );
};

export const resetPDF = () => {
  return {
    type: RESET_PDF,
  };
};

export const setCurrency = (currency: string) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: SET_CURRENCY,
    payload: currency,
  });
};

export const setField = (field: string, value: FormValue) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: SET_FIELD,
    payload: { field, value },
  });
};
