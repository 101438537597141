import { Product } from '@core/types/models';
import React, { useState } from 'react';

interface DragListProps {
  items: Product[];
  onChange: (updatedItems: Product[]) => void;
  limit: number;
}

const DragList: React.FC<DragListProps> = ({ items, onChange, limit }) => {
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLIElement>, hoverIndex: number) => {
    event.preventDefault();
    if (draggedIndex !== null && draggedIndex !== hoverIndex) {
      const updatedItems = [...items];
      const dragItem = updatedItems[draggedIndex];
      updatedItems.splice(draggedIndex, 1);
      updatedItems.splice(hoverIndex, 0, dragItem);
      onChange(updatedItems);
      setDraggedIndex(hoverIndex);
    }
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleUp = (index: number) => {
    if (index > 0) {
      const updatedItems = [...items];
      const dragItem = updatedItems[index];
      updatedItems.splice(index, 1);
      // Inactive products are always made active for ease of use
      const newIndex = Math.min(index - 1, limit - 1);
      updatedItems.splice(newIndex, 0, dragItem);
      onChange(updatedItems);
    }
  };

  const handleDown = (index: number) => {
    if (index < items.length - 1) {
      const updatedItems = [...items];
      const dragItem = updatedItems[index];
      updatedItems.splice(index, 1);
      updatedItems.splice(index + 1, 0, dragItem);
      onChange(updatedItems);
    }
  };

  return (
    <ul className="form__fields-drag-list">
      {items.map((item, index) => (
        <>
          <li data-inactive={index >= limit} className="form__fields-drag-list-number">
            {index + 1}
          </li>
          <li
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
            data-dragging={draggedIndex === index}
            data-inactive={index >= limit}
            className="form__fields-drag-list-item"
          >
            <span>{item.variantName}</span>
            <button onClick={() => handleUp(index)} type="button" className="form__fields-drag-list-item-up" aria-label="up" />
            <button onClick={() => handleDown(index)} type="button" className="form__fields-drag-list-item-down" aria-label="down" />
          </li>
        </>
      ))}
    </ul>
  );
};

export default DragList;
