import { FormattedNumberValue, Item } from '@core/types/models';
import React, { FunctionComponent, useState } from 'react';

interface FormattedNumberProps {
  id: string;
  value?: FormattedNumberValue;
  placeholder?: string;
  onChange: (value: FormattedNumberValue) => void;
  required?: boolean;
  valid?: boolean;
  formats: Item[];
  setFormat?: (number: string) => void;
  selectedFormat?: string;
}

export const formatNumber = (value?: number, number?: string) => {
  if (!value || !number) {
    return '';
  }

  return number.replace('[[x]]', value.toString());
};

const FormattedNumber: FunctionComponent<FormattedNumberProps> = ({
  id,
  value = null,
  placeholder,
  onChange,
  required = false,
  valid = true,
  selectedFormat,
  formats,
  setFormat,
}) => {
  if (!selectedFormat) {
    selectedFormat = formats[0].value;
  }

  const [isFocused, setIsFocused] = useState(false);

  const handleFormatChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newNumber = e.target.value;
    setFormat && setFormat(newNumber);
  };

  const handleValueChange = (newValue: string) => {
    const rawValue = parseFloat(newValue);

    if (!isNaN(rawValue)) {
      const formattedValue = formatNumber(rawValue, selectedFormat);
      onChange({ rawValue, formattedValue });
    } else {
      onChange({ rawValue: undefined, formattedValue: '' });
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const displayValue = isFocused ? value?.rawValue?.toString() || '' : formatNumber(value?.rawValue || 0, selectedFormat);

  return (
    <div className={`form__row ${!valid ? 'invalid' : ''}`}>
      <div className="form__control">
        {formats && formats.length > 1 ? (
          <select
            id={`${id}-number`}
            value={selectedFormat}
            onChange={(e) => handleFormatChange(e)}
            className="form__control form__control--select"
          >
            {formats.map(({ key, value }) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </select>
        ) : (
          <div className="form__control--icon">{formats[0]?.key || ''}</div>
        )}
        <input
          type="text"
          id={id}
          placeholder={`${placeholder} ${required ? '*' : ''}`}
          value={displayValue}
          onChange={(e) => handleValueChange(e.currentTarget.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </div>
      <label htmlFor={id} className="form__label">
        {`${placeholder} ${required ? '*' : ''}`}
      </label>
    </div>
  );
};

export default FormattedNumber;
