import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { States } from '@core/types';
import { t } from './index';

interface IProps {
  translations?: States.ITranslation;
  resourceString: string;
}

const Translate: FC<IProps> = ({ translations, resourceString }) => {
  if (!translations || translations.isLoading || translations.values.length === 0) {
    return null;
  }

  return <Fragment>{t(resourceString)}</Fragment>;
};

const mapStateToProps = (state: States.IRootState) => ({
  translations: state.translation,
});

export default connect(
  mapStateToProps,
  null,
)(Translate);
