import { States } from '@core/types';
import {
  GENERATE_PDF,
  GENERATE_PDF_SUCCESS,
  SEND_FOR_APPROVAL,
  SEND_FOR_APPROVAL_SUCCESS,
  SET_PDF_MODAL,
  CLEAR_PDF,
  SET_ORDER_DETAILS,
  FETCH_PROJECT_PDF_SUCCESS,
  SET_ITEM_TYPE,
  RESET_PDF,
  SET_CURRENCY,
  SET_FIELD,
} from './actions';
import { PdfSettingsActionTypes } from './actions';
import { PDFType } from '@core/enums/project';
import { currencies } from 'config';

const initialState: States.IPdfSettingsState = {
  itemType: null,
  printPDF: {
    valid: true,
    projectPdf: null,
    modal: {
      isOpen: false,
      isGenerating: false,
      isSendingForApproval: false,
      orderDetails: null,
    },
  },
  downloadPDF: {
    valid: true,
    projectPdf: null,
    modal: {
      isOpen: false,
      isGenerating: false,
      isSendingForApproval: false,
      orderDetails: null,
    },
  },
  currency: currencies[0].value,
  formData: {},
};

function projectReducer(state: States.IPdfSettingsState = initialState, action: PdfSettingsActionTypes): States.IPdfSettingsState {
  switch (action.type) {
    case SET_ITEM_TYPE: {
      const newState = { ...state };

      newState.itemType = action.payload;

      return newState;
    }

    case GENERATE_PDF: {
      const newState = { ...state };

      if (action.payload === PDFType.SendToPrinters) {
        newState.printPDF.modal.isGenerating = true;
        newState.downloadPDF.valid = true;
      }

      if (action.payload === PDFType.Download) {
        newState.downloadPDF.modal.isGenerating = true;
        newState.downloadPDF.valid = true;
      }

      return newState;
    }

    case GENERATE_PDF_SUCCESS: {
      const newState = { ...state };

      if (action.payload.type === PDFType.SendToPrinters) {
        if (newState.printPDF.valid) {
          newState.printPDF.projectPdf = action.payload.response.projectPDF;

          newState.printPDF.modal.orderDetails = null;
          newState.printPDF.modal.isGenerating = false;
        }
      }

      if (action.payload.type === PDFType.Download) {
        if (newState.downloadPDF.valid) {
          newState.downloadPDF.projectPdf = action.payload.response.projectPDF;

          newState.downloadPDF.modal.isGenerating = false;
        }
      }

      return newState;
    }

    case SEND_FOR_APPROVAL: {
      const newState = { ...state };

      newState.printPDF.modal.isSendingForApproval = true;

      return newState;
    }

    case SEND_FOR_APPROVAL_SUCCESS: {
      const newState = { ...state };

      newState.printPDF.modal.isSendingForApproval = false;

      return newState;
    }

    case SET_PDF_MODAL: {
      const newState = { ...state };

      if (action.payload.pdfType === PDFType.Download) {
        if (action.payload.isGenerating !== undefined) {
          newState.downloadPDF.modal.isGenerating = action.payload.isGenerating;
        }

        if (action.payload.isOpen !== undefined) {
          newState.downloadPDF.modal.isOpen = action.payload.isOpen;
        }

        if (action.payload.isSendingForApproval !== undefined) {
          newState.downloadPDF.modal.isSendingForApproval = action.payload.isSendingForApproval;
        }
      }

      if (action.payload.pdfType === PDFType.SendToPrinters) {
        if (action.payload.isGenerating !== undefined) {
          newState.printPDF.modal.isGenerating = action.payload.isGenerating;
        }

        if (action.payload.isOpen !== undefined) {
          newState.printPDF.modal.isOpen = action.payload.isOpen;
        }

        if (action.payload.isSendingForApproval !== undefined) {
          newState.printPDF.modal.isSendingForApproval = action.payload.isSendingForApproval;
        }

        if (action.payload.showConfirmation !== undefined) {
          newState.printPDF.modal.showConfirmation = action.payload.showConfirmation;
        }
      }

      return newState;
    }

    case CLEAR_PDF: {
      const newState = { ...state };

      if (action.payload === PDFType.Download) {
        newState.downloadPDF.projectPdf = null;
      }

      if (action.payload === PDFType.SendToPrinters) {
        newState.printPDF.projectPdf = null;
      }

      return newState;
    }

    case SET_ORDER_DETAILS: {
      const newState = { ...state };

      newState.printPDF.modal.orderDetails = action.payload;

      return newState;
    }

    case FETCH_PROJECT_PDF_SUCCESS: {
      const newState = { ...state };

      newState.printPDF.projectPdf = action.payload.projectPDF;
      newState.printPDF.modal.isOpen = true;

      return newState;
    }

    case RESET_PDF: {
      const newState = { ...state };

      if (newState.printPDF.modal.isGenerating) {
        newState.printPDF = {
          modal: {
            isGenerating: false,
            isOpen: false,
            isSendingForApproval: false,
            orderDetails: null,
          },
          projectPdf: null,
          valid: false,
        };
      }

      if (newState.downloadPDF.modal.isGenerating) {
        newState.downloadPDF = {
          modal: {
            isGenerating: false,
            isOpen: false,
            isSendingForApproval: false,
            orderDetails: null,
          },
          projectPdf: null,
          valid: false,
        };
      }

      return newState;
    }

    case SET_CURRENCY: {
      const newState = { ...state };

      newState.currency = action.payload;

      return newState;
    }

    case SET_FIELD: {
      const newState = { ...state, formData: { ...state.formData, [action.payload.field]: action.payload.value } };

      return newState;
    }

    default:
      return state;
  }
}

export default projectReducer;
