import { Item } from '@core/types/models';
import React, { useState, FunctionComponent, useEffect } from 'react';

interface IProps {
  id: string;
  placeholder?: string;
  includeSelectedItem?: boolean;
  onChange: (value: string) => void;
  options?: Item[];
  value: string;
  required?: boolean;
  valid?: boolean;
  asRow?: boolean;
}

const Dropdown: FunctionComponent<IProps> = ({
  id,
  placeholder,
  includeSelectedItem,
  options,
  onChange,
  value,
  required = false,
  valid = true,
}) => {
  const _placeholder = placeholder ? `${placeholder} ${required ? '*' : ''}` : '';
  const firstValue = options?.find((x) => x.key === value);
  const [isOpen, setIsOpen] = useState(false);
  const [_value, setValue] = useState(firstValue?.value || _placeholder);

  useEffect(() => {
    const body = document.querySelector('body');
    if (!body) {
      return;
    }

    body.addEventListener('click', _close);

    return () => body.removeEventListener('click', _close);
  }, []);

  const _setIsOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  const _close = () => {
    setIsOpen(false);
  };

  const onChangeInternal = (item: Item) => {
    setValue(item.value);
    onChange(item.key);
    setIsOpen(false);
  };

  const filteredItems = () => {
    if (!includeSelectedItem) {
      return options?.filter((x) => x.value !== _value);
    }

    return options;
  };

  return (
    <div className={`form__control form__row`} data-placeholder-shown={_value === _placeholder} id={id}>
      <div className={`form__dropdown ${isOpen ? 'active' : ''} ${!valid ? 'invalid' : ''}`}>
        <div className="form__dropdown-selected" onClick={_setIsOpen} dangerouslySetInnerHTML={{ __html: _value }}></div>
        <div className="form__dropdown-values">
          {filteredItems()?.map((x) => (
            <div
              key={x.key}
              className="form__dropdown-value"
              onClick={() => onChangeInternal(x)}
              dangerouslySetInnerHTML={{ __html: x.value }}
            ></div>
          ))}
        </div>
      </div>
      {_placeholder && <label className="form__label">{`${placeholder} ${required ? '*' : ''}`}</label>}
    </div>
  );
};

export default Dropdown;
