import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { Dropdown } from '@components/shared';
import { Loader } from '@components/loaders';
import { States } from '@core/types';
import Checkbox from '@components/shared/Checkbox';
import { t } from '@components/translations';
import { PDFItemType, PDFFlyerStep, PDFType, PDFFlyerStepOption } from '@core/enums/project';
import { FormValue, Template, Product, Item, Field, ProductField, FormattedNumberValue } from '@core/types/models';
import Textbox from '@components/shared/Textbox';
import ImageUploader from '@components/shared/ImageUploader';
import DragList from '@components/shared/DragList';
import { PDFProps } from '@core/types/api';
import FormattedNumber from '@components/shared/FormattedNumber';
import { PDFFlyerStepOptions, PDFFlyerTemplateOptions, PDFTagTemplateOptions } from 'config';
import { ProductService } from '@core/services';

export interface IPdfSettingsProps {
  project: States.IProjectState;
  app: States.IAppState;
  pdfSettings: States.IPdfSettingsState;
  product: States.IProductState;
  onClose: () => void;
  type: PDFType;
  handleGeneratePDF: (props: PDFProps) => void;
  onSubmit: () => void;
  setPdfItemType: (pdfItemType: PDFItemType | null) => void;
  setCurrency: (FormattedNumber: string) => void;
  setField: (key: string, value: FormValue) => void;
}
const getDiscount = (standardPrice: number, campaignPrice: number): number => {
  const discount = 100 - (campaignPrice / standardPrice) * 100;
  return Math.round(discount * 100) / 100;
};

const PdfSettings: FunctionComponent<IPdfSettingsProps> = ({
  project,
  app,
  pdfSettings,
  product,
  onClose,
  type,
  handleGeneratePDF,
  onSubmit,
  setPdfItemType,
  setCurrency,
  setField,
}) => {
  const [requiresReview, setRequiresReview] = useState(type === PDFType.SendToPrinters);
  const [flyerStep, setFlyerStep] = useState<PDFFlyerStep | null>(null);
  const [flyerSteps, setFlyerSteps] = useState<PDFFlyerStepOption[]>([]);
  const [currentProductIndex, setCurrentProductIndex] = useState<number>(0);
  const [template, setFlyerTemplate] = useState<Template | null>(null);
  const [templates, setFlyerTemplates] = useState<Template[] | null>(null);
  const [orderVisited, setOrderVisited] = useState<boolean>(false);
  const formInnerRef = useRef<HTMLDivElement>(null);

  const prevItemTypeRef = useRef(pdfSettings.itemType);

  useEffect(() => {
    const updateSteps = (template: Template | null) => {
      const steps: PDFFlyerStepOption[] = [];
      template?.fields.forEach((x) => {
        const step = PDFFlyerStepOptions[x.step];
        if (!steps.includes(step)) {
          steps.push(step);
        }
      });
      setFlyerSteps(steps);
      setFlyerStep(0);
    };

    if (prevItemTypeRef.current !== pdfSettings.itemType) {
      prevItemTypeRef.current = pdfSettings.itemType;

      const templates = pdfSettings.itemType === PDFItemType.Flyer ? PDFFlyerTemplateOptions : PDFTagTemplateOptions;

      setFlyerTemplates(templates);

      const template = templates[0];
      setFlyerTemplate(template);
      updateSteps(template);
    } else {
      updateSteps(template);
    }
  }, [pdfSettings.itemType, template]);

  const [products, setFlyerProducts] = useState<Product[]>(
    project.currentProject
      ? project.currentProject.products.flatMap((x) => {
          const productInfo = ProductService.flatten(product.currentCategories).find((y) => y.variantAgilityID === x.productID);
          return productInfo ? Array.from({ length: x.quantity }, () => productInfo) : [];
        })
      : [],
  );

  const updateOrder = (updatedProducts: Product[]) => {
    setFlyerProducts(updatedProducts);
    setCurrentProductIndex(0);
  };

  const handleSubmitInternal = () => {
    if (pdfSettings.itemType === null || template === null || !flyerStepComplete(flyerSteps.length - 1)) {
      return;
    }

    handleGeneratePDF({
      type,
      itemType: pdfSettings.itemType,
      template: template,
      formData: pdfSettings.formData,
      products: products.slice(0, Math.min(products.length, template.products)),
    });

    return;
  };

  const flyerStepComplete = (step: PDFFlyerStep, productIndex?: number) => {
    if (template === null) {
      return false;
    }

    const requiredFields = template.fields.filter((x) => x.step <= step).filter((x) => x.required);

    const orderProductsIndex = requiredFields.findIndex((x) => x.type === 'orderProducts');

    if (orderProductsIndex !== -1) {
      requiredFields.splice(orderProductsIndex, 1);
      if (!orderVisited) {
        return false;
      }
    }

    const productsIndex = requiredFields.findIndex((x) => x.type === 'products');
    if (productsIndex !== -1 && template.productFields) {
      requiredFields.splice(productsIndex, 1);

      const requiredProductsFields = template.productFields.filter((x) => x.required);

      const requiredProducts = products.slice(
        0,
        productIndex === undefined ? Math.min(products.length, template.products) : productIndex + 1,
      );

      for (const flyerProduct of requiredProducts) {
        if (!flyerProduct.formData) {
          return false;
        }

        if (
          !flyerProduct.formData ||
          requiredProductsFields.some((x) => flyerProduct.formData[x.name] === undefined || flyerProduct.formData![x.name] === '')
        ) {
          return false;
        }
      }
    }

    if (requiredFields.some((x) => pdfSettings.formData[x.name] === undefined || pdfSettings.formData[x.name] === '')) {
      return false;
    }

    return true;
  };

  const dropdownCultures = app.cultures
    .map((x) => ({
      key: x.cultureCode,
      value: t(x.cultureName),
    }))
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

  const renderField = (
    fields: Record<string, FormValue>,
    field: Field | ProductField,
    setField: (key: string, value: FormValue) => void,
  ): JSX.Element | null => {
    if (field.hidden || template === null || templates === null) {
      return null;
    }

    switch (field.type) {
      case 'string':
        return (
          <Textbox
            id={field.name}
            value={fields[field.name] as string}
            placeholder={field.label}
            required={field.required}
            onChange={(value) => setField(field.name, value)}
          />
        );

      case 'file':
        return (
          <ImageUploader
            id={field.name}
            value={fields[field.name] as string}
            placeholder={field.label}
            required={field.required}
            onChange={(value) => setField(field.name, value)}
          />
        );

      case 'select':
        let options: Item[] = [];
        let infoText: string | null = null;

        if (typeof field.options == 'object') {
          options = field.options;
        } else if (field.options === 'cultureCode') {
          options = dropdownCultures;
        } else if (field.options === 'template') {
          const allTemplates: Template[] =
            pdfSettings.itemType === PDFItemType.Flyer ? PDFFlyerTemplateOptions : PDFTagTemplateOptions;
          options = allTemplates.filter((x) => x.products <= products.length);

          if (options.length === 0) {
            infoText = 'No templates available for the current number of products.';
          } else if (allTemplates.length > options.length) {
            infoText = 'Some templates are not available for the current number of products.';
          }
        }

        return (
          <>
            <Dropdown
              id={field.name}
              placeholder={field.label}
              required={field.required}
              onChange={(value: string) => {
                setField(field.name, value);
                if (field.name === 'template') {
                  setFlyerTemplate(templates.find((x) => x.key === value) || templates[0]);
                }
              }}
              value={fields[field.name] as string}
              options={options}
            />
            {infoText && <p>{infoText}</p>}
          </>
        );

      case 'boolean':
        return (
          <Checkbox
            id={field.name}
            label={field.label}
            required={field.required}
            value={fields[field.name] as boolean}
            onClick={() => setField(field.name, !fields[field.name])}
          />
        );

      case 'orderProducts':
        return (
          <>
            <p>
              Drag and drop to order products. The first <b>{template.products}</b> products will be included in the flyer.
            </p>
            <DragList items={products} onChange={(updatedProducts) => updateOrder(updatedProducts)} limit={template.products} />
          </>
        );

      case 'products':
        return (
          <>
            <p>
              Input some further information to{' '}
              {template.products === 1 ? (
                'the product'
              ) : (
                <>
                  product <b>{currentProductIndex + 1}</b>
                </>
              )}{' '}
              on the flyer.
            </p>
            <p className="product-info">
              <div className="product-info__content">
                <b>Title:</b>
                <p>{products[currentProductIndex].title}</p>
                <b>SKU:</b>
                <p>{products[currentProductIndex].sku}</p>
                <b>Features:</b>
                <p>
                  {products[currentProductIndex].features && (
                    <ul className="product-info__content__features-list">
                      {products[currentProductIndex].features?.map((feature, index) => (
                        <li key={`feature-${index}`}>{feature}</li>
                      ))}
                    </ul>
                  )}
                </p>
              </div>
              <img alt="presentation" src={products[currentProductIndex].heroImage} />
            </p>
            {template?.productFields?.map((field) =>
              renderField(products[currentProductIndex].formData || {}, field, (key, value) => {
                const updatedFlyerProducts = [...products];

                updatedFlyerProducts[currentProductIndex].formData = {
                  ...updatedFlyerProducts[currentProductIndex].formData,
                  [key]: value,
                };

                if (key === 'standardPrice' || key === 'campaignPrice') {
                  const standardPrice = updatedFlyerProducts[currentProductIndex].formData?.standardPrice as FormattedNumberValue;
                  const campaignPrice = updatedFlyerProducts[currentProductIndex].formData?.campaignPrice as FormattedNumberValue;

                  if (standardPrice?.rawValue && campaignPrice?.rawValue) {
                    const discount = getDiscount(standardPrice.rawValue, campaignPrice.rawValue);
                    updatedFlyerProducts[currentProductIndex].formData = {
                      ...updatedFlyerProducts[currentProductIndex].formData,
                      percentagePriceDifference: {
                        rawValue: discount,
                        formattedValue: `${discount}%`,
                      },
                    };
                  }
                }

                setFlyerProducts(updatedFlyerProducts);
              }),
            )}
          </>
        );

      case 'formattedNumber':
        return (
          <FormattedNumber
            id={field.name}
            value={fields[field.name] as FormattedNumberValue}
            placeholder={field.label}
            onChange={(value) => {
              setField(field.name, value);
            }}
            required={field.required}
            formats={field.options as Item[]}
            {...(['standardPrice', 'campaignPrice'].includes(field.name) && {
              selectedFormat: pdfSettings.currency,
              setFormat: setCurrency,
            })}
          />
        );
      default:
        return null;
    }
  };

  const renderStepContent = (stepEnum: PDFFlyerStep) => {
    if (template === null || templates === null) {
      return null;
    }

    const step = PDFFlyerStepOptions[stepEnum];
    const fields = template.fields.filter((x) => x.step === stepEnum);
    return (
      <div className="form__fields">
        <h2>{step.longTitle || step.title}</h2>
        {step.description && <p dangerouslySetInnerHTML={{ __html: t(step.description) }} />}

        <div className={step.id === PDFFlyerStep.FORMAT ? 'form__fields--format' : ''}>
          <div>{fields.map((field) => renderField(pdfSettings.formData, field, setField))}</div>
          {step.id === PDFFlyerStep.FORMAT && (
            <embed
              src={`${template.pdf}#toolbar=0&navpanes=0&scrollbar=0#view=FitH`}
              data-template={template.key}
              title={template.key}
            />
          )}
        </div>
      </div>
    );
  };

  const renderGenerateFlyer = () => {
    if (flyerStep === null || template === null) {
      return;
    }

    return (
      <div className="form__navigation-wrapper">
        {/* STEP NAVIGATION */}
        {flyerSteps.length > 1 && (
          <div className="form__navigation-wrapper__items">
            {flyerSteps.map((step, index) => (
              <button
                data-active={index === flyerStep}
                key={index}
                disabled={index > 0 ? !flyerStepComplete(index - 1) : false}
                onClick={() => setFlyerStep(index)}
              >
                {step.title}
              </button>
            ))}
          </div>
        )}

        <div className="form__navigation-wrapper__content">
          <div className="form__inner" ref={formInnerRef}>
            {renderStepContent(flyerStep)}
          </div>

          <div className="form__button-wrapper">
            {/* PREVIOUS STEP */}
            {flyerStep === PDFFlyerStep.PRODUCTS && currentProductIndex > 0 ? (
              <button className="btn btn--previous" onClick={() => setCurrentProductIndex(currentProductIndex - 1)}>
                Previous Product
              </button>
            ) : flyerStep > 0 ? (
              <button className="btn btn--previous" onClick={() => setFlyerStep(flyerStep + -1)}>
                {flyerSteps[flyerStep - 1].title}
              </button>
            ) : (
              <button className="btn" onClick={() => onClose && onClose()}>
                Cancel
              </button>
            )}

            {/* NEXT STEP */}
            {flyerStep === PDFFlyerStep.PRODUCTS && currentProductIndex < Math.min(products.length, template.products) - 1 ? (
              <button
                onClick={() => setCurrentProductIndex(currentProductIndex + 1)}
                className="btn btn--next"
                disabled={!flyerStepComplete(flyerStep, currentProductIndex)}
              >
                Next Product
              </button>
            ) : flyerStep < flyerSteps.length - 1 ? (
              <button
                onClick={() => setFlyerStep(flyerStep + 1)}
                className="btn btn--next"
                disabled={!flyerStepComplete(flyerStep, currentProductIndex)}
              >
                {flyerSteps[flyerStep + 1].title}
              </button>
            ) : (
              <button onClick={handleSubmitInternal} className="btn">
                Create PDF
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (formInnerRef.current) {
      formInnerRef.current.scrollTop = 0;
    }

    if (!orderVisited && flyerStep === PDFFlyerStep.ORDER) {
      setOrderVisited(true);
    }
  }, [flyerStep]);

  if (pdfSettings.downloadPDF.modal.isGenerating) {
    return (
      <div className="form__inner">
        <div className="form__fields">
          <h2 className="align--center">Generating PDF</h2>
          <p className="align--center" dangerouslySetInnerHTML={{ __html: t('ftg.generatepdf.generic') }}></p>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {pdfSettings.itemType === null && (
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Choose Your Item Type</h2>
            <p className="align--center">Select the type of item you wish to generate.</p>
            <div className="form__button-wrapper">
              <button className="btn" onClick={() => setPdfItemType(PDFItemType.Flyer)}>
                Flyer
              </button>
              <button className="btn" onClick={() => setPdfItemType(PDFItemType.Tags)}>
                Tags
              </button>
            </div>
          </div>
        </div>
      )}

      {pdfSettings.itemType !== null &&
        pdfSettings.downloadPDF.projectPdf === null &&
        !pdfSettings.downloadPDF.modal.isGenerating &&
        renderGenerateFlyer()}

      {pdfSettings.downloadPDF.modal.isGenerating && (
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Generating PDF</h2>
            <p className="align--center" dangerouslySetInnerHTML={{ __html: t('ftg.generatepdf.download') }}></p>
            <Loader />
          </div>
        </div>
      )}

      {pdfSettings.downloadPDF.projectPdf && !pdfSettings.downloadPDF.modal.isGenerating && (
        <Fragment>
          <div className="form__inner">
            <div className="form__fields">
              <h2 className="align--center">{type === PDFType.Download ? 'Download your PDF' : 'Review your PDF'}</h2>
              {type === PDFType.Download ? (
                <p className="align--center">Your PDF is ready to download. Click below to download your PDF.</p>
              ) : (
                <>
                  <p className="align--center">You must view your PDF before sending to the printers</p>
                  {pdfSettings.printPDF.projectPdf && (
                    <p className="align--center">
                      Click{' '}
                      <a
                        href={pdfSettings.printPDF.projectPdf.pdfUrl}
                        onClick={() => setRequiresReview(false)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>{' '}
                      to review your PDF
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <button className="btn" onClick={onSubmit} disabled={requiresReview}>
            {type === PDFType.Download ? 'Download PDF' : 'Send to Printers'}
          </button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PdfSettings;
