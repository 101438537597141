import React, { FunctionComponent, Fragment, useState } from 'react';
import { Models, States, Api } from '@core/types';
import { ProductRow } from '@components/products';
import { usePassportContext } from '@tti/passport';
import { PassportEnums } from '@tti/passport';

interface IProps {
  project: States.IProjectState;
  categories: Models.Category[];
  selectedIds: Record<number, number[] | undefined>;
  onChange: (mapping: Record<number, number[]>) => void;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
}

const ProjectDetailTable: FunctionComponent<IProps> = ({ project, categories, selectedIds, updateProject, onChange }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const [accordions, setAccordions] = useState<string[]>([]);

  const isActive = (id: string) => {
    return accordions.indexOf(id) === -1;
  };

  const toggleAccordion = (id: string) => {
    let newState = [];
    const existingIndex = accordions.indexOf(id);

    if (existingIndex > -1) {
      newState = accordions.filter(x => x !== id);
    } else {
      newState = [
        ...accordions,
        id,
      ];
    }

    setAccordions(newState);
  };

  const getQuantity = (agilityId: number) => {
    return project.currentProject?.products.find(x => x.productID === agilityId)?.quantity || 0;
  };

  const handleUpdateQuantity = (params: Api.IUpdateQuantity) => {
    if (!project || !project.currentProject || !cultureClaim || !passportContext.bearerToken) {
      return;
    }

    let refetchProject = false;

    // Copy the products
    const newProducts = [...project.currentProject.products];

    // Find the index of the existing product to update
    const existingIndex = newProducts.findIndex(x => x.productID === params.agilityID);

    if (existingIndex === -1) {
      return;
    }

    if (params.quantity <= 0) {
      newProducts.splice(existingIndex, 1);

      // We technically only need to refetch the products when one gets deleted
      refetchProject = true;
    } else {
      newProducts[existingIndex].quantity = params.quantity;
    }

    updateProject({
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      projectGUID: project.currentProject.projectGUID,
      projectProducts: newProducts,
      refetchProject,
    });
  };

  const addProducts = (childCategories: Models.Category[]) => {
    const mapping: Record<number, number[]> = {};

    for (const childCategory of childCategories) {
      const agilityIds = childCategory.childProducts.map(x => x.variantAgilityID);
      
      mapping[childCategory.categoryAgilityID] = agilityIds;
    }

    onChange(mapping);
  }

  const renderSubCategory = (childCategory: Models.Category) => {
    const selectedCategoryIds = selectedIds[childCategory.categoryAgilityID];
    let allSelected = false;

    if (selectedCategoryIds !== undefined) {
      allSelected = childCategory.childProducts.every(x => selectedCategoryIds.indexOf(x.variantAgilityID) > -1);
    }

    return (
      <div key={childCategory.categoryName} className="selected-products__sub-category-container">
      <ProductRow
        title={childCategory.categoryName}
        isGrey={true}
        isSelected={allSelected}
        onChange={() => addProducts([childCategory])}
      />
      <div className="selected-products__products">
        {childCategory.childProducts.map(childProduct => (
          <ProductRow
            key={`${childProduct.variantAgilityID}|${getQuantity(childProduct.variantAgilityID)}`}
            isDark={true}
            title={childProduct.variantName} // this value
            productAgilityId={childProduct.variantAgilityID}
            onChange={() => onChange({ [childCategory.categoryAgilityID]: [childProduct.variantAgilityID]})}
            isSelected={selectedCategoryIds ? selectedCategoryIds.indexOf(childProduct.variantAgilityID) > -1 : false}
            quantity={getQuantity(childProduct.variantAgilityID)}
            onUpdateQuantity={handleUpdateQuantity}
          />
        ))}
      </div>
    </div>
    );
  }

  return (
    <Fragment>
      {categories.map(category => (
        <div key={category.categoryName} className="selected-products__container">
          <ProductRow
            title={category.categoryName}
            isTitle={true}
            showAccordion={true}
            isGrey={true}
            accordionOpen={isActive(category.categoryName)}
            // onChange={() => addProducts(category.childCategories)}
            isSelected={false}
            onAccordionClick={() => toggleAccordion(category.categoryName)}
          />
          <div className={`selected-products__category-container ${isActive(category.categoryName) ? 'is-active' : ''}`}>
            {category.childCategories.map(childCategory => renderSubCategory(childCategory))}
          </div>
        </div>
      ))}
    </Fragment >
  );
};

export default ProjectDetailTable;
