import React from 'react';
import PdfSettings, { IPdfSettingsProps } from './PdfSettings';
import ProjectDetailsForm from './ProjectDetailsForm';
import { PDFType } from '@core/enums/project';

interface IProps extends Omit<IPdfSettingsProps, 'sizes' | 'type'> {
  setOrderDetails: (formData: Record<string, string> | null) => void;
}

const PdfPrintSettings: React.FunctionComponent<IProps> = ({
  app,
  project,
  pdfSettings,
  product,
  onClose,
  handleGeneratePDF,
  setOrderDetails,
  onSubmit,
  setPdfItemType,
  setCurrency,
  setField,
}) => {
  const handleSendForApproval = () => {
    if (!pdfSettings.printPDF.modal.isSendingForApproval) {
      return;
    }

    onSubmit();
  };

  if (pdfSettings.printPDF.modal.orderDetails === null) {
    return (
      <ProjectDetailsForm
        internationalPhoneNumberPrefixes={app.internationalPhoneNumberPrefixes}
        formData={pdfSettings.printPDF.modal.orderDetails}
        countries={app.countries}
        invoiceCountries={app.invoiceCountries}
        onSubmit={setOrderDetails}
        hasSubmit={false}
      />
    );
  }

  return (
    <PdfSettings
      project={project}
      app={app}
      pdfSettings={pdfSettings}
      product={product}
      handleGeneratePDF={handleGeneratePDF}
      onClose={onClose}
      type={PDFType.SendToPrinters}
      onSubmit={handleSendForApproval}
      setPdfItemType={setPdfItemType}
      setCurrency={setCurrency}
      setField={setField}
    />
  );
};

export default PdfPrintSettings;
